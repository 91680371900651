<template>
  <v-container fluid class="fill-height">
    <v-row class="fill-height">
      <v-col md="2" class="hidden-sm-and-down" />
      <v-col md="8">
        <v-card rounded outlined class="mx-auto fill-height">
          <v-toolbar flat>
            <v-toolbar-title>Mijn account</v-toolbar-title>
          </v-toolbar>
          <v-sheet class="py-4">
            <v-row>
              <v-col cols="12" lg="3">
                <v-subheader>Mijn profiel</v-subheader>
              </v-col>
              <v-col cols="12" lg="8">
                <v-form readonly class="px-4">
                  <v-text-field
                    v-model="firstName"
                    label="Voornaam"
                  ></v-text-field>
                  <v-text-field v-model="lastName" label="Naam"></v-text-field>
                </v-form>
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet class="py-4">
            <v-row>
              <v-col cols="12" lg="3">
                <v-subheader>Mijn historiek</v-subheader>
              </v-col>
              <v-col cols="12" lg="8">
                <v-timeline>
                  <v-timeline-item
                    v-for="(orderEvent, i) in orderHistory"
                    :key="i"
                    :icon="
                      orderEvent.eventType == 'COLLECT'
                        ? 'mdi-map-marker-outline'
                        : 'mdi-truck-outline'
                    "
                    color="white"
                    icon-color="primary"
                    fill-dot
                  >
                    <h6 class="text-caption font-weight-light">
                      {{ orderEvent.eventDate }}
                    </h6>
                    <p class="text-body-2 text-justify">
                      {{ orderEvent.eventDescr }}
                    </p>
                  </v-timeline-item>
                </v-timeline>
              </v-col>
            </v-row>
          </v-sheet>
        </v-card>
      </v-col>
      <v-col md="2" class="hidden-sm-and-down" />
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "EAccount",
  data() {
    return {
      loading: false
    };
  },
  created() {
    this.loading = true;
    this.$store.dispatch("account/fetchOrderHistory").finally(() => {
      this.loading = false;
    });
  },
  computed: {
    ...mapGetters({
      firstName: "account/firstName",
      lastName: "account/lastName",
      orderHistory: "account/orderHistory"
    })
  }
};
</script>
